import * as React from "react"
import Layout, { layoutContext } from "../components/layout"
import Seo from "../components/seo"
import { features } from '../contents/about.yaml'
import content from "../contents/about-allied.yaml"

import ico_about_1 from '../images/ico_about_1.svg'
import ico_about_2 from '../images/ico_about_2.svg'
import ico_about_3 from '../images/ico_about_3.svg'
import ico_about_4 from '../images/ico_about_4.svg'
import ico_about_5 from '../images/ico_about_5.svg'
import ico_about_6 from '../images/ico_about_6.svg'

const icons = [ico_about_1, ico_about_2, ico_about_3, ico_about_4, ico_about_5, ico_about_6]


const AboutPage = () => {


  return (
    <Layout>
      <layoutContext.Consumer>
        {
          ({ setPopupStatus }) => (
            <>
              <Seo title={content.title} keywords={content.keywords} description={content.description} />
              <div className="container mt-16 lg:mt-[100px]">
                <div className="m-auto max-w-[750px] text-center">
                  <h3 className="text-h3 lg:text-h2 font-bold mb-8">We’re In For The Long Haul! </h3>
                  <p className="mb-[70px]">Whether you just need someone to help with the heavy lifting or don't want to lift a finger—we'll be there for you every step of the way. 20 years of experience has equipped us with all the right skills to help you relocate your home across country or over state lines.</p>
                </div>


                <div className="grid lg:grid-cols-3 gap-x-[100px] gap-y-[60px]">

                  {features.map((feature, index) =>
                    <div class="m-about_feature">
                      <div className="flex lg:ml-10">
                        <div className="bg-green w-[55px] h-[55px] lg:h-[37px] grid place-items-center lg:-skew-x-12">
                          <div className="lg:skew-x-12">
                            <img src={icons[index]} alt="" />
                          </div>
                        </div>
                        <h3 className="font-bold mb-8 lg:mb-4 max-w-[180px] ml-3">{feature.title}</h3>
                      </div>
                      <p className="lg:text-micro lg:pl-8">{feature.desc}</p>
                    </div>
                  )}

                </div>
              </div>





              <div className="container mt-16 lg:mt-[150px] bg-green py-16 lg:rounded-lg">
                <div className="m-auto max-w-[830px] text-center">
                  <h3 className="text-h3 text-white lg:text-h2 font-bold mb-4">Wanna See What 5 Star Moving Looks Like?</h3>
                  <p className="text-white">Here’s is a little sneak peek into what moving with us is all about</p>
                  <input className="lg:inline-block mt-8 btn btn-white px-4" type="button" value="GET YOUR QUOTE NOW" onClick={e => setPopupStatus(true)} />

                </div>
              </div>





            </>
          )
        }
      </layoutContext.Consumer>





    </Layout>
  )
}


export default AboutPage
